<template>
    <div>
        <v-card class="card">
            <v-card-subtitle class="titleFont--text font-weight-medium">
              <v-row>
                <v-col cols="9" class="py-2 pl-0 pr-3">
              <v-icon class="ml-1 titleFont--text">mdi-map-marker</v-icon>
              <span v-text= "address.address" ></span>
              </v-col>
               <v-col class="px-0 py-2 d-flex justify-end">
            <optionMenu color="muted" size="20"/>
            </v-col>
            </v-row>
            </v-card-subtitle>
            

            <v-row class="mr-2">
              <v-col cols="1" class="py-2">
                <v-icon>mdi-sign-real-estate</v-icon>
              </v-col>
              <v-col class="py-2">
                <span class="medium-font mr-1"><span v-text= "address.province"></span>/ <span v-text= "address.city"></span></span>
              </v-col>
            </v-row>

            <v-row v-if="address.postal_code" class="mr-2">
              <v-col cols="1" class="py-2">
                <v-icon>mdi-email</v-icon>
              </v-col>
              <v-col class="py-2">
                <span class="medium-font mr-1" v-text="address.postal_code"></span>
              </v-col>
            </v-row>

            <v-row class="mr-2">
              <v-col cols="1" class="pa-2">
                <v-icon>mdi-cellphone-iphone</v-icon>
              </v-col>
              <v-col class="py-2">
                <span class="medium-font mr-1" v-text= "address.mobile"></span>
              </v-col>
            </v-row>

            <v-row v-if="address.phone" class="mr-2">
              <v-col cols="1" class="py-2">
                <v-icon>mdi-phone</v-icon>
              </v-col>
              <v-col class="py-2">
                <span class="medium-font mr-1" v-text= "address.phone"></span>
              </v-col>
            </v-row>

            <v-card-actions>
              <v-spacer></v-spacer>
              <div @click="removeAddress()" >
              <circleBtn icon="mdi-trash-can-outline" color="primary"/>
              </div>
            </v-card-actions>
          </v-card>
    </div>
</template>
<script>
import { deletedAddress } from '../../models/address'
import optionMenu from '@/views/shop/Basket/components/lists/dotsMenu'
export default {
  components: {
    optionMenu
  },
  props: ['address'],
  methods: {
    removeAddress () {
      deletedAddress(this.$props.address.id)
    }
  }
}
</script>
